import { useCallback, useEffect, useReducer, useRef } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getCashOutRoutesData } from "@hyperlocal/banking-utility";
import { Button, toast } from "@hyperlocal/vital2";
import { Html5Qrcode, Html5QrcodeResult } from "html5-qrcode";
import { useNavigate } from "react-router-dom";
import {
  disableTorch,
  enableTorch,
  getCapabilities,
} from "../../utils/capabilities";

const ACTION_TYPES = {
  SET_TORCH: "SET_TORCH",
  SET_TORCH_CAPABILITY: "SET_TORCH_CAPABILITY",
  START_SCANNER: "START_SCANNER",
  STOP_SCANNER: "STOP_SCANNER",
} as const;

type Action =
  | { type: typeof ACTION_TYPES.SET_TORCH; payload: boolean }
  | { type: typeof ACTION_TYPES.SET_TORCH_CAPABILITY; payload: boolean }
  | { type: typeof ACTION_TYPES.STOP_SCANNER };

interface State {
  torch: boolean;
  hasTorchCapability: boolean;
}

const initialState: State = {
  torch: false,
  hasTorchCapability: false,
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case ACTION_TYPES.SET_TORCH:
      return { ...state, torch: action.payload };
    case ACTION_TYPES.SET_TORCH_CAPABILITY:
      return { ...state, hasTorchCapability: action.payload };
    case ACTION_TYPES.STOP_SCANNER:
      return { ...state, torch: false };
    default:
      return state;
  }
}

type BarcodeReaderProps = {
  onReadSuccess: (
    decodedText: string,
    decodedResult: Html5QrcodeResult,
  ) => void;
  onReadError: (errorMessage: string) => void;
};

export function BarcodeReader({
  onReadSuccess,
  onReadError,
}: Readonly<BarcodeReaderProps>) {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const scannerRef = useRef<Html5Qrcode>(null);

  const stopScanner = useCallback(async () => {
    await scannerRef.current?.stop();
  }, []);

  const startScanner = useCallback(async () => {
    try {
      if (scannerRef.current === null) {
        scannerRef.current = new Html5Qrcode("reader");

        await scannerRef.current.start(
          { facingMode: "environment" },
          { fps: 10 },
          (decodedText, decodedResult) => {
            onReadSuccess(decodedText, decodedResult);
            scannerRef.current.clear();
          },
          onReadError,
        );
      }
    } catch (err) {
      console.error("Failed to start QR Code scanner", err);
      toast({
        title: "Erro",
        description: "Falha ao iniciar leitor de código de barras",
        variant: "error",
        position: "top-right",
      });
      navigate(getCashOutRoutesData().cashOutBoletoCopiaCola);
    }
  }, [navigate, onReadError, onReadSuccess]);

  const toggleTorch = () => {
    const isTorchOn = state.torch;
    const newTorchState = !isTorchOn;
    if (newTorchState) {
      enableTorch();
    } else {
      disableTorch();
    }
    dispatch({ type: "SET_TORCH", payload: newTorchState });
  };

  useEffect(() => {
    startScanner();
    return () => {
      stopScanner();
    };
  }, [startScanner, stopScanner]);

  useEffect(() => {
    getCapabilities().then((capabilities) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore *teste, não é listada no typescript, faremos testes para funcionamento
      dispatch({ type: "SET_TORCH_CAPABILITY", payload: capabilities.torch });
    });
  }, []);

  async function handleCancel() {
    await stopScanner();
    navigate(getCashOutRoutesData().dashboard);
  }

  async function handleRedirectToCopyPaste() {
    await stopScanner();
    navigate(getCashOutRoutesData().cashOutBoletoCopiaCola);
  }

  async function handleRedirectToPix() {
    await stopScanner();
    navigate(getCashOutRoutesData().cashOutPix);
  }

  return (
    <div
      style={{ height: window.innerHeight + "px" }}
      className={`relative flex w-full flex-1 flex-col items-center justify-center`}
    >
      <div className="absolute bottom-0 left-0 right-0 top-0 z-40">
        <div
          className={`absolute bottom-0 right-0 z-50 flex h-full w-full max-w-[30.33333%] flex-1 flex-col items-center justify-around whitespace-nowrap bg-primary-darker p-6 text-white opacity-90`}
        >
          <Button.Root
            type="button"
            onClick={handleCancel}
            className={"absolute top-[40px] rotate-90 justify-center"}
          >
            <Button.Slot name="SettingTrashClose" />
          </Button.Root>
          <span className="rotate-90 items-center font-base text-sm font-bold">
            Posicione a linha vermelha sobre o código de barras.
          </span>

          {state.hasTorchCapability && (
            <Button.Root
              variant="primary"
              type="button"
              onClick={toggleTorch}
              className="absolute bottom-[40px] rotate-90 justify-center text-neutral-white"
            >
              <Button.Slot name="NotificatinosLampCharge" />
            </Button.Root>
          )}
        </div>

        <div
          id="reader"
          className="relative flex h-full w-full items-center justify-center [&>video]:h-full [&>video]:object-cover"
        />
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="z-50 h-full w-[2px] bg-red-500" />
        </div>
        <div
          className={`absolute left-0 top-0 z-50 flex h-full w-full max-w-[30.33333%] flex-1 flex-col items-center justify-around bg-primary-darker p-6 text-white opacity-90`}
        >
          <Button.Root
            variant={"primary"}
            onClick={handleRedirectToPix}
            aria-label={"QR Code Pix"}
            className={"w-auto min-w-[180px] rotate-90 justify-center"}
          >
            QR Code Pix
          </Button.Root>

          <Button.Root
            variant={"primary"}
            onClick={handleRedirectToCopyPaste}
            aria-label={"Digitar o código de barras"}
            className={
              "w-auto min-w-[180px] rotate-90 justify-center whitespace-nowrap"
            }
          >
            Digitar o código de barras
          </Button.Root>
        </div>
      </div>
    </div>
  );
}
