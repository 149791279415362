import styled from "styled-components";

export const Container = styled.div`
  .reader {
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100dvh;
  }

  #qr-shaded-region {
    /* 100% da tela menos o tamanho do quadrado do leitor, dividindo por dois pois é cima/baixo e direita/esquerda */
    border-width: 4px;
  }

  video {
    width: unset !important;
    height: 100dvh;
  }
`;
