import { createContext, ReactNode, useContext, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { ICashOutRoutes } from "@hyperlocal/banking-utility";

type RoutesContextProps = {
  children: ReactNode;
};

type RoutesContextType = {
  routes: ICashOutRoutes;
  setRoutes: (routes: ICashOutRoutes) => void;
};

export const RoutesContext = createContext<RoutesContextType>(
  {} as RoutesContextType,
);

export const RoutesContextProvider = ({ children }: RoutesContextProps) => {
  const [routes, setRoutes] = useState<ICashOutRoutes>();

  return (
    <RoutesContext.Provider value={{ routes, setRoutes }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  const value = useContext(RoutesContext);

  return value;
};
