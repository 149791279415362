const formatDateToISO = (date: Date) => {
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const formatDateToBR = (date: Date) => {
  return date.toLocaleDateString();
};

const formatBRToISO = (date: string) => {
  if (!date) return;

  const [day, month, year] = date.split("/");

  return `${month}-${day}-${year}`;
};

export { formatDateToISO, formatDateToBR, formatBRToISO };
