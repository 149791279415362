import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: ${theme.spacings.inline.xs};
    width: 100%;
    margin-top: ${theme.spacings.stack.sm};
  `}
`;

export const Contact = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.stack.quarck};
    flex: 1 auto;
  `}
`;

export const Small = styled.small`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.x4s};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};
  `}
`;

export const Name = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};
  `}
`;

export const Info = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.x2s};
    line-height: ${theme.lineHeights.sm};
    color: ${theme.palette.neutral.dark};
  `}
`;
