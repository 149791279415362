import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      height: calc(100vh - 56px);
      background-color: ${theme.palette.neutral.white};
      align-items: center;
      justify-content: space-between;
  `)}

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      padding: ${`${theme.spacings.inset.lg} ${theme.spacings.stack.xl}`};
      align-items: stretch;
      border-radius: ${theme.borderRadius.sm};
      max-width: 1248px;
      margin: 0 auto;

      min-height: 100vh;
      height: auto;
      justify-content: flex-start;
  `)}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings.stack.x2s};

    &,
    button {
      width: 100%;
      justify-content: center;
    }
  `}
`;

export const Outer = styled.div`
  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
     background-color: ${theme.palette.neutral.lighter};
  `)}
`;

export const PageTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.palette.neutral.darkest};

  margin: ${({ theme }) => theme.spacings.stack.x2s} 0;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.stack.nano};
`;

export const PaymentWrapper = styled.div`
  // COMMON
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;

  position: relative;

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      width: 100%;
      grid-template-columns: 1fr;
  `)}

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      background: ${theme.palette.neutral.lighter};
      gap: 24px;
  `)}
`;

export const BalanceWrapper = styled.div`
  // COMMON
  display: flex;
  flex-direction: column;
  height: 100%;

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      gap: 24px;
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      width: 100%;
  `)}
`;

export const ContainerMain = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme }) => theme.palette.neutral.white};

  height: 100%;
  overflow: auto;

  position: absolute;
  inset: 0;
  grid-column: 2;

  & > div {
    height: 100%;
  }
`;

export const CopyPasteWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`  
      padding: 24px;
      background-color: ${theme.palette.neutral.white};
      border-radius: ${theme.borderRadius.sm};
      min-height: 543px;
  `)}

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`  
      padding: 32px 16px;
  `)}
`;
