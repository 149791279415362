/*
 *   Documentação utilizada para cálculo:
 *   https://www.bb.com.br/docs/pub/emp/empl/dwn/Doc5175Bloqueto.pdf
 *   https://cmsarquivos.febraban.org.br/Arquivos/documentos/PDF/Layout%20-%20C%C3%B3digo%20de%20Barras%20ATUALIZADO.pdf
 */

import { cleanBarcode, modulo10, modulo11Arrecadacao } from "./modulo";

const convertBoleto = (barcode: string): string => {
  const barCode = cleanBarcode(barcode);
  if (Number(barCode[0]) === 8) return boletoArrecadacao(barCode);
  return boletoBanco(barCode);
};

const boletoArrecadacao = (barcode: string | number): string => {
  const barCode = barcode;
  const identificador = String(barCode).charAt(2);
  const bloco1 = String(barCode).slice(0, 11);
  const bloco2 = String(barCode).slice(11, 22);
  const bloco3 = String(barCode).slice(22, 33);
  const bloco4 = String(barCode).slice(33, 44);
  const modulo =
    identificador === "6" || identificador === "7"
      ? modulo10
      : identificador === "8" || identificador === "9"
      ? modulo11Arrecadacao
      : "error";
  if (modulo === "error") {
    return "Digito verificador inválido";
  }
  const bloco1Digito = bloco1 + modulo(bloco1);
  const bloco2Digito = bloco2 + modulo(bloco2);
  const bloco3Digito = bloco3 + modulo(bloco3);
  const bloco4Digito = bloco4 + modulo(bloco4);

  return bloco1Digito + bloco2Digito + bloco3Digito + bloco4Digito;
};

const boletoBanco = (barcode: string | number): string => {
  const barCode = barcode;
  const bloco1 =
    String(barCode).slice(0, 4) +
    String(barCode).slice(19, 20) +
    String(barCode).slice(20, 24);
  const bloco2 = String(barCode).slice(24, 29) + String(barCode).slice(29, 34);
  const bloco3 = String(barCode).slice(34, 39) + String(barCode).slice(39, 44);
  const bloco4 = String(barCode).slice(4, 5);
  const bloco5 = String(barCode).slice(5, 19);
  const bloco1Digito = bloco1 + modulo10(bloco1);
  const bloco2Digito = bloco2 + modulo10(bloco2);
  const bloco3Digito = bloco3 + modulo10(bloco3);

  return bloco1Digito + bloco2Digito + bloco3Digito + bloco4 + bloco5;
};

export { boletoArrecadacao, boletoBanco };
export default convertBoleto;
