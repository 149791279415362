import { useRef } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { formatCurrency } from "@hyperlocal/banking-utility";
import { Button } from "@hyperlocal/vital2";
import { useSuspenseQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { usePrint } from "../../hooks/usePrint";
import apis from "../../services";
import { VerticalLogo } from "./vertical-logo";

const listSchedule = async (id: string) => await apis.listSchedule(id);
const listScheduleQueryKey = {
  default: (id: string) => ["list-scheduled", id],
};

const useSchedule = (id: string) => {
  return useSuspenseQuery({
    queryFn: () => listSchedule(id),
    queryKey: listScheduleQueryKey.default(id),
    select: (data) => data.items[0],
  });
};

type ScheduledPaymentReceiptProps = {
  id: string;
};

export const ScheduledPaymentReceipt = ({
  id,
}: ScheduledPaymentReceiptProps) => {
  const { data } = useSchedule(id);
  const ref = useRef<HTMLDivElement>(null);

  const handlePrint = usePrint({
    content: () => ref.current,
  });

  return (
    <div
      className="flex h-full flex-col justify-between p-6 print:p-10 print:[&_button]:hidden"
      ref={ref}
    >
      <div>
        <h4 className="font-inter -mt-2 pb-4 text-2xl font-bold text-neutral-darkest">
          Comprovante
        </h4>

        <div className="flex flex-col items-center justify-center gap-6 border-b border-b-[#CCCCCC] p-6">
          <VerticalLogo />

          <div className="space-y-2 text-center">
            <h1 className="text-[20px] font-bold text-[#1A1A1A]">
              Pagamento de boleto
            </h1>

            <h1 className="text-[20px] font-bold text-primary-main">
              Agendado
            </h1>
          </div>
        </div>

        <div className="space-y-4 border-b border-b-[#CCCCCC] py-4">
          <div className="space-y-1">
            <p className="text-[14px] font-medium text-[#1A1A1A]">
              Código de barras
            </p>
            <p className="text-[#666666]">{data.barCodeNumber}</p>
          </div>

          <div className="flex gap-2">
            <p className="text-[14px] font-medium text-[#1A1A1A]">
              Beneficiário
            </p>
            <p className="ml-auto text-[#666666]">{data.assignor}</p>
          </div>

          <div className="flex gap-2">
            <p className="text-[14px] font-medium text-[#1A1A1A]">CPF/CNPJ</p>
            <p className="ml-auto text-[#666666]">{data.assignorDocument}</p>
          </div>

          <div className="flex gap-2">
            <p className="text-[14px] font-medium text-[#1A1A1A]">
              Valor do documento
            </p>
            <p className="ml-auto text-[#666666]">
              {formatCurrency(data.amount)}
            </p>
          </div>

          <div className="flex gap-2">
            <p className="text-[14px] font-medium text-[#1A1A1A]">
              Data Agendada
            </p>
            <p className="ml-auto text-[#666666]">
              {format(new Date(data.scheduleDate), "dd/MM/yyyy")}
            </p>
          </div>
        </div>
      </div>

      <Button.Root className="print:hidden" onClick={handlePrint}>
        <Button.Slot name="ComputersPrinter" />
        Imprimir comprovante
      </Button.Root>
    </div>
  );
};
