import IntlCurrencyInput from "react-intl-currency-input";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) =>
    theme.breakpoints.desktop(`
     margin-bottom: 24px;
  `)}
`;

export const Label = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.medium};

    text-align: center;
    width: 100%;
    margin-bottom: ${theme.spacings.stack.nano};
    color: ${theme.palette.neutral.darker};
  `}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      font-size: ${theme.fontSizes.xs};
  `)}

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      font-size: ${theme.fontSizes.x2s};
  `)}
`;

export const Value = styled.div`
  ${({ theme }) => css`
    gap: ${theme.spacings.inline.x2s};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 283px;
    border-bottom: 1px solid ${theme.palette.neutral.light};
  `}
`;

export const Currency = styled.small`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.sm};
    font-size: ${theme.fontSizes.xs};
    color: ${theme.palette.neutral.darkest};
  `}
`;

export const SInput = styled(IntlCurrencyInput)`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights.sm};
    font-size: ${theme.fontSizes.lg};
    color: ${theme.palette.primary.main};
    background: ${theme.palette.neutral.white};

    &:disabled {
      margin-right: 24px;
    }

    border: none;
    outline: none;
    width: 100%;
    text-align: center;
  `}
`;

export const ClearInput = styled.button`
  background-color: unset;
  border: none;
  margin: 0px !important;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;

  .closeIcon {
    fill: ${({ theme }) => theme.palette.neutral.light};
  }
`;

interface ErrorProps {
  helper: string;
}

export const Caption = styled.span<ErrorProps>`
  ${(props) => css`
    order: 3;
    font-family: ${props.theme.fonts.base};
    font-weight: ${props.theme.fontWeights.regular};
    font-size: ${props.theme.fontSizes.x2s};
    line-height: ${props.theme.lineHeights.md};

    color: ${props.theme.palette.neutral.dark};

    transition: color 300ms ease;
  `}

  ${(props) => {
    const types = {
      info: css``,
      error: css`
        color: ${props.theme.palette.status.error.errorDefault};
      `,
      success: css`
        color: ${props.theme.palette.status.success.successDefault};
      `,
    };

    return props?.helper ? types[props.helper] : "";
  }}

  ${(props) => css`
    input:focus ~ & {
      color: ${props.theme.palette.neutral.dark};
    }
  `}
`;
