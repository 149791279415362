import { ComponentProps } from "react";

export function ArrowLeft(props: ComponentProps<"svg">) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {" "}
      <path
        d="M13.475 17.125l-4.5-4.5a.822.822 0 01-.188-.288.935.935 0 010-.675.824.824 0 01.188-.287l4.5-4.5A.72.72 0 0114 6.65c.2 0 .375.075.525.225a.72.72 0 01.225.525.72.72 0 01-.225.525L10.45 12l4.075 4.075a.72.72 0 01.225.525.72.72 0 01-.225.525.72.72 0 01-.525.225.72.72 0 01-.525-.225z"
        fill="currentColor"
      />{" "}
    </svg>
  );
}

export const BarCode = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.833008 14.3332V11.3332H1.83301V13.3332H3.83301V14.3332H0.833008ZM12.1663 14.3332V13.3332H14.1663V11.3332H15.1663V14.3332H12.1663ZM2.66634 12.4998V4.49984H3.99967V12.4998H2.66634ZM4.66634 12.4998V4.49984H5.33301V12.4998H4.66634ZM6.66634 12.4998V4.49984H7.99967V12.4998H6.66634ZM8.66634 12.4998V4.49984H10.6663V12.4998H8.66634ZM11.333 12.4998V4.49984H11.9997V12.4998H11.333ZM12.6663 12.4998V4.49984H13.333V12.4998H12.6663ZM0.833008 5.6665V2.6665H3.83301V3.6665H1.83301V5.6665H0.833008ZM14.1663 5.6665V3.6665H12.1663V2.6665H15.1663V5.6665H14.1663Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};
