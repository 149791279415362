import { useCallback, useEffect, useRef } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { getCashOutRoutesData } from "@hyperlocal/banking-utility";
import { toast } from "@hyperlocal/vital2";
import { Html5Qrcode } from "html5-qrcode";
import { useNavigate } from "react-router-dom";
import * as Styles from "./styles";

export default function QrCodeReader({ onDetected }) {
  const navigate = useNavigate();
  const scanner = useRef<Html5Qrcode | null>(null);

  const onNewScanResult = useCallback(
    (decodedText, decodedResult) => {
      if (scanner.current.getState() === 2) {
        scanner.current.stop();
      }
      onDetected(decodedText, decodedResult);
    },
    [onDetected],
  );

  const startCamera = useCallback(async () => {
    try {
      await scanner.current.start(
        { facingMode: "environment" },
        {
          fps: 30,
          qrbox: 250,
          aspectRatio: 2,
        },
        onNewScanResult,
        null,
      );
    } catch (err) {
      console.error("Failed to start QR Code scanner", err);
      toast({
        title: "Erro",
        description: "Falha ao iniciar leitor de código de barras",
        variant: "error",
        position: "top-right",
      });
      navigate(getCashOutRoutesData().cashOutBoletoCopiaCola);
    }
  }, [navigate, onNewScanResult]);

  useEffect(() => {
    if (scanner.current) {
      if (scanner.current.getState() === 1) {
        startCamera();
      }
    } else {
      scanner.current = new Html5Qrcode("reader");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scanner.current]);

  return (
    <Styles.Container>
      <div id={"reader"} className="reader" />
    </Styles.Container>
  );
}
