import { useState } from "react";
import {
  API,
  Hooks,
  useAccountStore,
  useUserStore,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, toast, Token } from "@hyperlocal/vital2";
import { v4 as uuidv4 } from "uuid";
import { PixDecoded } from "../CopyPaste";

type TransferPixResponse = {
  idEndToEnd: string;
  transactionId: string;
  status: string;
  amount: number;
  name: string;
  document: string;
  key: string;
  idAdjustment: number;
  transactionCode: string;
};

interface TokenSheetProps {
  pix: PixDecoded;
  description?: string;
  goToNextStep?: (response: TransferPixResponse) => void;
  cafToken: string;
}

export function TokenSheet({
  pix,
  description,
  goToNextStep,
  cafToken,
}: Readonly<TokenSheetProps>) {
  const [totpCode, setTotpCode] = useState("");
  const { currentAccountId: accountId } = useAccountStore();
  const {
    user: { userId, accounts },
  } = useUserStore();
  const isMobile = Hooks.useMediaQuery("mobile");

  async function handleVerification() {
    try {
      const vertical =
        accounts.find((account) => account.accountId === accountId).vertical ||
        "Banking";

      const { data } = await API.pix.post<TransferPixResponse>(
        "/api/v1/pix/Transfer",
        {
          accountId,
          userId,
          transferType: "KEY",
          vertical,
          externalCodeAuthorization: uuidv4(),
          token: totpCode,
          amount: pix.amount.original,
          description: description || "Transferência PIX",
          payee: {
            key: pix.payee.key,
          },
        },
        {
          headers: {
            caf_token: cafToken,
          },
        },
      );

      goToNextStep?.(data);
    } catch (error) {
      const errors = error?.response?.data?.errors;

      if (errors) {
        const message = errors[0].friendlyMessage;

        return toast({
          title: message,
          variant: "error",
        });
      }

      return toast({
        title: "Não foi possível finalizar o pagamento",
        variant: "error",
      });
    }
  }

  const isDisabled = totpCode.length !== 6;

  return (
    <div className="flex flex-1 flex-col">
      <h2 className="my-4 text-xs/md font-bold text-neutral-darkest mobile:text-xs">
        Informe o código de autenticação
      </h2>

      <div className="flex gap-2">
        <span>•</span>
        <span>
          Abra seu aplicativo Google Authenticator e insira o <br /> código de
          seis dígitos exibido no aplicativo.
        </span>
      </div>

      <div className="mt-8">
        <Token.Label className="mobile:text-x2s/default">
          Digite o código
        </Token.Label>
        <Token.Root onValueChange={setTotpCode}>
          <Token.Input className="input-token-cash-out-wildcard" />
          <Token.Input className="input-token-cash-out-wildcard" />
          <Token.Input className="input-token-cash-out-wildcard" />
          <Token.Input className="input-token-cash-out-wildcard" />
          <Token.Input className="input-token-cash-out-wildcard" />
          <Token.Input className="input-token-cash-out-wildcard" />
        </Token.Root>
      </div>

      <Button.Root
        variant="primary"
        className="mt-auto w-full justify-center"
        onClick={handleVerification}
        disabled={isDisabled}
      >
        Verificar
      </Button.Root>
    </div>
  );
}
