import {
  Types as UTypes,
  //@ts-ignore
} from "@hyperlocal/banking-utility";

//dou*********@email.com
const maskEmail = (email: string) => {
  const [beforeAt, afterAt] = email.split("@");
  const unMasked = beforeAt.substring(0, 4);
  const masked = beforeAt.substring(4, beforeAt.length - 1).replace(/./g, "*");

  return `${unMasked}${masked}@${afterAt}`;
};

//123aad*********************
const maskEVP = (evp: string) => {
  const unMasked = evp.substring(0, 6);
  const masked = evp.substring(6, evp.length - 1).replace(/./g, "*");

  return `${unMasked}${masked}`;
};

//154******56
export const maskCPF = (cpf: string) => {
  const firstUnMasked = cpf.substring(0, 3);
  const lastUnMasked = cpf.substring(9);
  const masked = cpf.substring(3, 9).replace(/./g, "*");
  return `${firstUnMasked}${masked}${lastUnMasked}`;
};

//30*********143
export const maskCNPJ = (cnpj: string) => {
  const firstUnMasked = cnpj.substring(0, 2);
  const lastUnMasked = cnpj.substring(11);
  const masked = cnpj.substring(2, 11).replace(/./g, "*");
  return `${firstUnMasked}${masked}${lastUnMasked}`;
};

//+5531993****96
const maskPhone = (phone: string) => {
  const firstUnMasked = phone.substring(0, 8);
  const lastUnMasked = phone.substring(12);
  const masked = phone.substring(8, 12).replace(/./g, "*");
  return `${firstUnMasked}${masked}${lastUnMasked}`;
};

export const maskKeyByType = (pix: UTypes["IPix"]) => {
  const maskMap: Record<string, string> = {
    EMAIL: maskEmail(pix.key),
    EVP: maskEVP(pix.key),
    CPF: maskCPF(pix.key),
    CNPJ: maskCNPJ(pix.key),
    PHONE: maskPhone(pix.key),
  };

  return maskMap[pix.type] || pix.key;
};
