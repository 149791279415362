// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getBoletoData, Types as UTypes } from "@hyperlocal/banking-utility";
import { formatDateToBR } from "./date";

export function validateIsFixed(payment: UTypes["IBoleto"]) {
  const maxAmount =
    Number(payment.maxPaymentAmount) || Number(payment.maxAmount);
  const minAmount =
    Number(payment.minPaymentAmount) || Number(payment.minAmount);

  return maxAmount > 0 && maxAmount !== minAmount;
}

export function getPaymentData(data: ReturnType<typeof getBoletoData>) {
  const formattedDate = formatDateToBR(new Date(data.dueDate));

  return {
    ...data,
    amount: data.fineValue,
    dueDate: formattedDate || data.dueDate,
  };
}

export function isFixedValue(data: ReturnType<typeof getBoletoData>) {
  if (data.indAllowsChangeValue === "true") {
    return validateIsFixed(data);
  }

  return true;
}

export const getMaxPaymentAmount = (
  payment: UTypes["IBoleto"],
): number | undefined => {
  return Number(payment.maxPaymentAmount) || Number(payment.maxAmount);
};

export const getMinPaymentAmount = (
  payment: UTypes["IBoleto"],
): number | undefined => {
  return Number(payment.minPaymentAmount) || Number(payment.minAmount);
};
