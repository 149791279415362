import styled, { css } from "styled-components";

export const Container = styled.div`
  // DESKTOP
  ${({ theme }) =>
    theme.breakpoints.desktop(`
      margin: ${theme.spacings.inset.lg}
    `)}
`;

export const DataList = styled.dl`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & + dl {
      ${({ theme }) =>
        theme.breakpoints.desktop(`
        margin-top: ${theme.spacings.stack.x3s};
      `)}

      ${({ theme }) =>
        theme.breakpoints.mobile(`
        margin-top: ${theme.spacings.stack.nano};
      `)}
    }

    &:last-of-type {
      margin-bottom: ${theme.spacings.stack.x2s};
    }

    dt,
    dd {
      font-family: ${theme.fonts.base};
      line-height: ${theme.lineHeights.md};
    }
  `}
`;

export const DataTitle = styled.dt`
  ${({ theme }) => css`
    color: ${theme.palette.neutral.darker};
    font-weight: ${theme.fontWeights.medium};
    text-align: start;
    min-width: max-content;
    font-size: ${theme.fontSizes.x2s};
  `}
`;

export const DataDescription = styled.dd`
  ${({ theme }) => css`
    color: ${theme.palette.neutral.dark};
    font-weight: ${theme.fontWeights.regular};
    text-align: start;
    word-break: break-all;
  `}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      font-size: ${theme.fontSizes.xs};
      &.amount {
        font-size: ${theme.fontSizes.sm};
        font-weight: ${theme.fontWeights.bold};
      }
  `)}

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      font-size: ${theme.fontSizes.x2s};
  `)}
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.bold};

    line-height: ${theme.lineHeights.md};
  `}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      font-size: ${theme.fontSizes.sm};
      margin-bottom: ${theme.spacings.stack.x3s};
  `)}

  ${({ theme }) =>
    theme.breakpoints.mobile(`
      font-size: ${theme.fontSizes.xs};
      margin: ${theme.spacings.stack.nano} 0px;
  `)}
`;
