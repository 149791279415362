import React from "react";
import { Button } from "@hyperlocal/vital";
import * as Styles from "./styles";
import * as Types from "./types";

function functionDefault() {
  // Por falta de tipos não é possível prever o que pode quebrar tirando essa
  // função default que não faz nada. Por isso, é melhor deixa-lá por enquanto.
  console.log("Essa função não faz nada: functionDefault");
}

export const ErrorDrawerContent: React.FC<Types.IErrorDrawerContent> = ({
  onClose = functionDefault,
  onSubmit = functionDefault,
  ...props
}) => {
  return (
    <Styles.Container>
      {props?.type === "boleto" ? (
        <>
          <Styles.DescriptionBoleto>
            {props?.description.toLowerCase()}
          </Styles.DescriptionBoleto>
          <Styles.Code>Código de barras</Styles.Code>
          <Styles.Barcode>{props?.code}</Styles.Barcode>
          <Button onClick={() => onClose()}>Pagar outro</Button>
          <Button variant="secondary" onClick={() => onSubmit()}>
            Fechar
          </Button>
        </>
      ) : (
        <>
          <Styles.Description>{props.description}</Styles.Description>
          <Button variant="secondary" onClick={() => onClose()}>
            Tentar novamente
          </Button>
          <Button onClick={() => onSubmit(props.code)}>Pix copia e cola</Button>
        </>
      )}
    </Styles.Container>
  );
};

export default ErrorDrawerContent;
