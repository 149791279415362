const getCapabilities = () => {
  return navigator.mediaDevices
    .getUserMedia({ video: { facingMode: "environment" } })
    .then((mediaStream) => {
      const track = mediaStream.getVideoTracks()[0];

      return track.getCapabilities();
    });
};

// Ligar flash experimental*
const enableTorch = () => {
  try {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((mediaStream) => {
        const track = mediaStream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore *teste, não é listada no typescript, faremos testes para funcionamento
        if (capabilities.torch) {
          track.applyConstraints({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore *teste, não é listada no typescript, faremos testes para funcionamento
            advanced: [{ torch: true }],
          });
        }
      });
  } catch (e) {
    console.error("Não foi possível ligar o flash");
  }
};

// Desligar flash experimental*
const disableTorch = () => {
  try {
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((mediaStream) => {
        const track = mediaStream.getVideoTracks()[0];
        const capabilities = track.getCapabilities();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore *teste, não é listada no typescript, faremos testes para funcionamento
        if (capabilities.torch) {
          track.applyConstraints({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore *teste, não é listada no typescript, faremos testes para funcionamento
            advanced: [{ torch: false }],
          });
        }
      });
  } catch (e) {
    console.error("Não foi possível ligar o flash");
  }
};

const getClipboardText = () => {
  return navigator.clipboard.readText();
};

export { enableTorch, disableTorch, getCapabilities, getClipboardText };
