import styled, { css } from "styled-components";

export const PixContainer = styled.div`
  ${({ theme }) => css`
    .headerIcon {
      fill: ${theme.palette.neutral.white};
      position: fixed;
      top: 0;
      margin: ${theme.spacings.stack.x2s} ${theme.spacings.inline.xs};
      width: 24px;
      z-index: 10;
      cursor: pointer;
    }
    .close {
      left: 0;
    }
    .torch {
      right: 0;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 285px;
    height: 285px;
    border-radius: 16px;
    box-shadow: 0 0 0 100vw ${theme.palette.primary.darker};
    opacity: 90%;
  `}
`;

export const QRCodeHelpTitle = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.sm};
    text-align: center;
    margin-bottom: ${theme.spacings.stack.xs};
    color: ${theme.palette.neutral.white};
    transform: translateY(-72px);
  `}
`;

export const QRCodeHelpText = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.sm};
    text-align: center;
    margin-top: ${theme.spacings.stack.x2s};
    color: ${theme.palette.neutral.white};
    transform: translateY(42px);
    bottom: 0;
    position: absolute;
    width: 100%;
  `}
`;

export const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: calc(100% - ${theme.spacings.inline.xs} * 2);
    gap: ${theme.spacings.inline.x2s};
    margin: ${theme.spacings.stack.xs} ${theme.spacings.inline.xs};
  `}
`;
