import { Boleto } from "broleto";
import { cleanBarcode } from "./modulo";

// Pega os dados do boleto pelo código de barras ou linha digitável

export interface IBoleto {
  barcode?: string;
  codeType?: string;
  type?: string;
  banks?: string;
  amount?: number;
  number?: string;
  prettyAmount?: string | number;
  valid?: boolean;
  expirationDate?: Date | "";
  expired?: boolean;
  expiredDays?: number | boolean | "";
  barcodeTyped?: string;
}

export function getBoletoByCode(code: string): IBoleto {
  const detected = new Boleto(cleanBarcode(code));
  if (!detected || !detected.valid()) {
    return null;
  }

  const boleto = detected.toJSON();
  const expiration = new Date(boleto?.expirationDate);
  const expirationYear = expiration.getFullYear();
  const expirationInvalid = !expirationYear || expirationYear < 2000;
  const expirationDate = expirationInvalid ? "" : boleto?.expirationDate;
  const expiredDays = expirationInvalid ? "" : boleto?.expiredDays;
  const expired = expirationInvalid ? false : boleto?.expired;

  return {
    ...detected,
    ...boleto,
    expirationDate,
    expired,
    expiredDays,
    barcodeTyped: code,
  };
}
