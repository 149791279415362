import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`
        padding: ${theme.spacings.stack.xs} ${theme.spacings.inline.xs};
        min-height: calc(100vh - 56px * 2);
    `)}

  // DESKTOP
    ${({ theme }) =>
    theme.breakpoints.desktop(`
        height: 500px;
    `)}

    .internalID {
    height: max-content;
  }

  button {
    margin-top: auto;
    justify-content: center;
  }
`;

export const DataList = styled.dl`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & + dl {
      margin-top: ${theme.spacings.stack.nano};
    }

    &:last-of-type {
      margin-bottom: ${theme.spacings.stack.x3s};
    }

    dt,
    dd {
      font-size: ${theme.fontSizes.x2s};
      font-family: ${theme.fonts.base};
      line-height: ${theme.lineHeights.md};
    }
  `}
`;

export const DataTitle = styled.dt`
  ${({ theme }) => css`
    color: ${theme.palette.neutral.darker};
    font-weight: ${theme.fontWeights.medium};
    text-align: start;
    min-width: max-content;
  `}
`;

export const DataDescription = styled.dd`
  ${({ theme }) => css`
    color: ${theme.palette.neutral.dark};
    font-weight: ${theme.fontWeights.regular};
    text-align: start;
    word-break: break-all;
  `}
`;

export const DrawerContent = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.regular};
    font-size: ${theme.fontSizes.x2s};
    line-height: ${theme.lineHeights.sm};
    color: ${theme.palette.neutral.darkest};
    white-space: pre-line;

    margin-top: ${theme.spacings.stack.x2s};
    height: 100%;

    // DESKTOP
    ${({ theme }) =>
      theme.breakpoints.desktop(`
        max-width: 480px;
    `)}

    button {
      justify-content: center;
      margin-top: ${theme.spacings.stack.xs};
    }

    #infos {
      padding: 0;
    }

    #receipt > button {
      margin-left: 0;
      margin-left: 0;
    }
  `}
`;
