import { Card } from "@hyperlocal/vital";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    * {
      font-family: ${theme.fonts.base};
      text-align: start;
    }
    margin-top: ${theme.spacings.stack.x2s};
  `}

  button {
    justify-content: center;
  }
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x2s};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.lg};
    color: ${theme.palette.neutral.darker};
  `}
`;

export const CodeType = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.x3s};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.md};
    margin-bottom: ${theme.spacings.stack.quarck};
    color: ${theme.palette.neutral.darkest};
  `}
`;

export const CardTitle = styled.p`
  ${Description}
  ${({ theme }) => css`
    color: ${theme.palette.neutral.darkest};
  `}
`;

export const CardDescription = styled.p`
  ${({ theme }) => css`
    font-size: ${theme.fontSizes.xs};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-all;
  `}
`;

export const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: flex-start;
  flex-direction: column;
`;

export const SCard = styled(Card)`
  display: flex;
  align-items: center;
  width: 100% !important;
  ${({ theme }) => css`
    color: ${theme.palette.neutral.darkest};
    gap: ${theme.spacings.inline.x2s};
    margin-top: ${theme.spacings.stack.x3s};
    margin-bottom: ${theme.spacings.stack.sm};
  `}
`;
