import { useState } from "react";
import { Input, RadioButton } from "@hyperlocal/vital";
import Icon from "@hyperlocal/vital-icons";
import { addDays, isToday, parseISO } from "date-fns";
import { formatDateToISO } from "../../../utils/date";
import * as Styles from "./styles";
import * as Types from "./types";

export function PaymentDate({
  onAlertClick,
  emitDateChange = () => {},
  paymentType,
}: Readonly<Types.IPaymentDate>) {
  const currentDate = new Date();

  const minDate = addDays(currentDate, 1);
  const maxDate = new Date(new Date().setMonth(minDate.getMonth() + 3));

  const [dateValue, setDateValue] = useState<string>(
    formatDateToISO(currentDate)
  );

  const isScheduleable = paymentType !== "PIX";
  const isDateValueToday = isToday(parseISO(dateValue));

  const handleChangeDate = (date: string) => {
    emitDateChange(date);
    setDateValue(date);
  };

  return (
    <Styles.Container>
      <Styles.Title>
        Data do pagamento
        {paymentType === "BOLETO" && (
          <Icon name="TagsAlertsInfoCircle" onClick={() => onAlertClick()} />
        )}
      </Styles.Title>

      <Styles.RadioButtonWrapper>
        <RadioButton
          checked={formatDateToISO(currentDate) === dateValue}
          disabled={!isScheduleable}
          onChangeValue={(checked) => {
            if (checked) {
              handleChangeDate(formatDateToISO(currentDate));
            }
          }}
        >
          Hoje
        </RadioButton>

        {isScheduleable && (
          <RadioButton
            checked={formatDateToISO(currentDate) !== dateValue}
            onChangeValue={(checked) => {
              if (checked) {
                handleChangeDate(formatDateToISO(minDate));
              }
            }}
          >
            Agendar
          </RadioButton>
        )}
      </Styles.RadioButtonWrapper>

      <Input
        disabled={isDateValueToday}
        className="dateInput"
        type="date"
        label="Data"
        value={dateValue}
        onChange={(e) => handleChangeDate(e.target.value)}
        min={formatDateToISO(minDate)}
        max={formatDateToISO(maxDate)}
      />
    </Styles.Container>
  );
}

export default PaymentDate;
