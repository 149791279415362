/*
 *   Documentação utilizada para cálculo:
 *   https://www.bb.com.br/docs/pub/emp/empl/dwn/Doc5175Bloqueto.pdf
 *   https://cmsarquivos.febraban.org.br/Arquivos/documentos/PDF/Layout%20-%20C%C3%B3digo%20de%20Barras%20ATUALIZADO.pdf
 */

export function modulo10(bloco: string): number {
  const codigo = bloco.split("").reverse();
  const somatorio = codigo.reduce((acc, current, index) => {
    let soma = Number(current) * (((index + 1) % 2) + 1);
    soma = soma > 9 ? Math.trunc(soma / 10) + (soma % 10) : soma;
    return acc + soma;
  }, 0);
  return Math.ceil(somatorio / 10) * 10 - somatorio;
}

export function modulo11Arrecadacao(bloco: string): number {
  const codigo = bloco.split("").reverse();
  let multiplicador = 2;
  const somatorio = codigo.reduce((acc, current) => {
    const soma = Number(current) * multiplicador;
    multiplicador = multiplicador === 9 ? 2 : multiplicador + 1;
    return acc + soma;
  }, 0);
  const resto = somatorio % 11;

  if (resto === 0 || resto === 1) {
    return 0;
  }
  if (resto === 10) {
    return 1;
  }
  return 11 - resto;
}

export function cleanBarcode(barcode: string): string {
  return barcode?.replace(/([ .-])/g, "");
}
