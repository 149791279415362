import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  // MOBILE
  ${({ theme }) =>
    theme.breakpoints.mobile(`
        padding: ${theme.spacings.stack.xs} ${theme.spacings.inline.xs};
        min-height: calc(100vh - 56px * 2);
    `)}

  // DESKTOP
    ${({ theme }) =>
    theme.breakpoints.desktop(`
        height: 500px;
    `)}

    .internalID {
    height: max-content;
  }

  button {
    margin-top: auto;
    justify-content: center;
  }
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.bold};
    font-size: ${theme.fontSizes.xs};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};

    margin-top: ${theme.spacings.stack.x2s};
    margin-bottom: ${theme.spacings.stack.nano};
  `}
`;

export const DrawerContent = styled.div`
  ${({ theme }) =>
    theme.breakpoints.desktop(`
        max-width: 480px;
    `)}
`;

export const DataList = styled.dl`
  ${({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & + dl {
      margin-top: ${theme.spacings.stack.nano};
    }

    &:last-of-type {
      margin-bottom: ${theme.spacings.stack.x2s};
    }

    dt,
    dd {
      font-size: ${theme.fontSizes.x2s};
      font-family: ${theme.fonts.base};
      line-height: ${theme.lineHeights.md};
    }
  `}
`;

export const DataTitle = styled.dt`
  ${({ theme }) => css`
    color: ${theme.palette.neutral.darker};
    font-weight: ${theme.fontWeights.medium};
    text-align: start;
    min-width: max-content;
  `}
`;

export const DataDescription = styled.dd`
  ${({ theme }) => css`
    color: ${theme.palette.neutral.dark};
    font-weight: ${theme.fontWeights.regular};
    text-align: start;
    word-break: break-all;
  `}
`;
