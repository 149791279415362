import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.stack.x3s};
  margin-bottom: ${({ theme }) => theme.spacings.stack.x2s};

  .dateInput {
    height: max-content;
    width: auto;
    max-width: max-content;
  }
`;

export const Title = styled.h4`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-weight: ${theme.fontWeights.bold};

    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};

    margin-top: ${theme.spacings.stack.x2s};

    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      width: 24px;
      height: 24px;
      fill: ${theme.palette.status.warning.warningDefault};
      margin-left: ${theme.spacings.inline.xs};
      cursor: pointer;
    }

    ${({ theme }) =>
      theme.breakpoints.desktop(`
        font-size: ${theme.fontSizes.sm};
    `)}

    ${({ theme }) =>
      theme.breakpoints.mobile(`
        font-size: ${theme.fontSizes.xs};
    `)}
  `}
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;

  input + label {
    width: 24px !important;
    height: 24px !important;
    border-radius: 50%!important;
  }
`;
