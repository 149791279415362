import { useReducer } from "react";

type FetchState = {
  errorStatus: boolean;
  errorMessage: string;
  isFetching: boolean;
};

type UpdateFetchStateAction = Partial<FetchState>;

function reducer(prev: FetchState, next: UpdateFetchStateAction) {
  return {
    ...prev,
    ...next,
  };
}

const initialFetchState: FetchState = {
  errorStatus: false,
  errorMessage: "",
  isFetching: false,
};

export function useFetchState() {
  const [fetchState, updateFetchState] = useReducer(reducer, initialFetchState);
  return [fetchState, updateFetchState] as const;
}
