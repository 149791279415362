import { Avatar } from "@hyperlocal/vital";
import { maskCNPJ, maskCPF } from "../../../utils/mask";
import * as Styles from "./styles";
import * as Types from "./types";

const CPF_LENGTH = 11;

export const PaymentContact: React.FC<Types.IPaymentContact> = ({
  ...props
}) => {
  const formatCpfCnpj = () => {
    return props.cpfCnpj.length > CPF_LENGTH
      ? maskCNPJ(props.cpfCnpj)
      : maskCPF(props.cpfCnpj);
  };

  return (
    <Styles.Container>
      <Avatar label={props.name} />
      <Styles.Contact>
        <Styles.Small>Para</Styles.Small>
        <Styles.Name>{props.name}</Styles.Name>
        <Styles.Info>{props.bankNameShort}</Styles.Info>
        {props.cpfCnpj && <Styles.Info>{formatCpfCnpj()}</Styles.Info>}
        <Styles.Info>{props.description}</Styles.Info>
      </Styles.Contact>
    </Styles.Container>
  );
};

export default PaymentContact;
