import { useCallback, useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getPixData,
  Hooks,
  IdentityConfirmationDialog,
  validateIdentity,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Drawer, Input } from "@hyperlocal/vital";
import { toast } from "@hyperlocal/vital2";
import { useLocation } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import PaymentContact from "../../../components/Payment/PaymentContact";
import PaymentDate from "../../../components/Payment/PaymentDate";
import PaymentValue from "../../../components/Payment/PaymentValue";
import { useFetchState } from "../../../hooks";
import { maskKeyByType } from "../../../utils/mask";
import { PixDecoded } from "../CopyPaste";
import * as Styles from "./styles";
import { TokenSheet } from "./token";
import * as Types from "./types";

type PixPaymentProps = Readonly<
  Types.IPixPayment & { resetPix?: () => void; pix?: PixDecoded }
>;

export function PixPayment({ resetPix, pix }: PixPaymentProps) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const { state } = useLocation();
  const payment: PixDecoded =
    pix || Object.keys(getPixData()).length > 0 || state?.pix;

  const [amount, setAmount] = useState(payment?.amount?.original || 0);
  const [drawer, setDrawer] = useState<Types.IDrawer>({ state: false });
  const [description, setDescription] = useState<string>("");
  const [fetchState] = useFetchState();
  const [isIdentityValidationDialogOpen, setIsIdentityValidationDialogOpen] =
    useState(false);

  const isValueFixed =
    (payment.type === "STATIC_QRCODE" && !payment.amount?.original) ||
    (payment.type === "DYNAMIC_QRCODE" && payment.allowChange);

  const handleInputChange = (value: number) => {
    setAmount(value);
  };

  const goToToken = async (cafToken: string) => {
    setDrawer({
      state: true,
      content: (
        <TokenSheet
          cafToken={cafToken}
          pix={{
            ...payment,
            amount: {
              original: amount,
            },
          }}
          description={description}
          goToNextStep={(response) =>
            setDrawer({
              state: true,
              content: (
                <Parcel
                  // @ts-ignore
                  config={() => System.import("@hyperlocal/banking-receipt")}
                  mountParcel={mountRootParcel}
                  receiptId={response.transactionCode}
                  receiptType="Pix"
                  movement="OUT"
                />
              ),
              title: "Comprovante",
            })
          }
        />
      ),
      title: "Validação",
    });
  };

  const toggleDrawerState = useCallback(() => {
    setDrawer((prevState) => ({
      ...prevState,
      state: !prevState.state,
      content: null,
    }));
  }, []);

  const handleCloseDrawer = () => {
    if (drawer?.title === "Comprovante") {
      toggleDrawerState();
      setTimeout(() => {
        resetPix();
      }, 200);
      return;
    }
    toggleDrawerState();
  };

  const handlePayment = async () => {
    await validateIdentity({
      onSuccess: (response) => {
        goToToken(response.attestation);
      },
      onFailure: (errorMessage) => {
        if (errorMessage === "Resource not found") {
          setIsIdentityValidationDialogOpen(true);
        } else {
          toast({
            title: "Erro",
            description: errorMessage,
            variant: "error",
            position: "top-right",
          });
        }
      },
    });
  };

  useEffect(() => {
    window.addEventListener("@hyperlocal-token-validation/close-drawer", () => {
      setDrawer((prevState) => ({ ...prevState, state: false }));
    });
  }, []);

  return (
    <>
      {isMobile && (
        // @ts-ignore
        <Parcel // @ts-ignore
          config={() => System.import("@hyperlocal/banking-balance")}
          mountParcel={mountRootParcel}
        />
      )}
      <Styles.Container>
        <PaymentValue
          amount={payment?.amount?.original}
          emitInputChange={handleInputChange}
          isFixed={!isValueFixed}
          helper={fetchState?.errorStatus ? "error" : "info"}
          helperText={fetchState?.errorStatus ? fetchState?.errorMessage : ""}
        />

        {isMobile && (
          <>
            <PaymentContact
              bankNameShort={payment?.payee?.bankName}
              cpfCnpj={payment?.payee?.document}
              name={payment?.payee?.name}
              description={payment?.details?.[0]?.content}
            />
            <PaymentDate paymentType="PIX" />
          </>
        )}

        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="internalID"
          type={"text"}
          label="Descrição da cobrança"
          placeholder="Digite aqui (Opcional)"
          maxLength={60}
        />

        {!isMobile && Object.keys(payment).length > 0 && (
          <>
            <Styles.Title>Dados do pagamento</Styles.Title>

            <Styles.DataList>
              <Styles.DataTitle>Favorecido</Styles.DataTitle>
              <Styles.DataDescription>
                {payment?.payee?.name}
              </Styles.DataDescription>
            </Styles.DataList>
            <Styles.DataList>
              <Styles.DataTitle>Chave Pix</Styles.DataTitle>
              <Styles.DataDescription>
                {maskKeyByType(payment?.payee)}
              </Styles.DataDescription>
            </Styles.DataList>
            <Styles.DataList>
              <Styles.DataTitle>Instituição</Styles.DataTitle>
              <Styles.DataDescription>
                {payment?.payee?.bankName}
              </Styles.DataDescription>
            </Styles.DataList>
            {payment?.details && (
              <Styles.DataList>
                <Styles.DataTitle>Descrição</Styles.DataTitle>
                <Styles.DataDescription>
                  {payment?.details[0]?.content}
                </Styles.DataDescription>
              </Styles.DataList>
            )}
          </>
        )}
        <IdentityConfirmationDialog
          isOpen={isIdentityValidationDialogOpen}
          onClose={setIsIdentityValidationDialogOpen}
        />

        <Drawer
          onDismiss={() => handleCloseDrawer()}
          position="right"
          title={drawer.title}
          open={drawer.state}
        >
          {drawer.content}
        </Drawer>

        <Button
          disabled={payment?.amount?.original <= 0}
          isLoading={fetchState.isFetching}
          onClick={handlePayment}
        >
          Pagar
        </Button>
      </Styles.Container>
    </>
  );
}

export default PixPayment;
