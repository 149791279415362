import React, { useEffect } from "react";
import { Avatar, Button } from "@hyperlocal/vital";
import { IconProps } from "@hyperlocal/vital-icons/dist";
import * as Styles from "./styles";
import * as Types from "./types";

function functionDefault() {
  // Por falta de tipos não é possível prever o que pode quebrar tirando essa
  // função default que não faz nada. Por isso, é melhor deixa-lá por enquanto.
  console.log("Essa função não faz nada: functionDefault");
}

export const ClipboardDrawerContent: React.FC<
  Types.IClipboardDrawerContent
> = ({ onSubmit = functionDefault, ...props }) => {
  const [iconName, setIconName] = React.useState<IconProps["name"]>();

  useEffect(() => {
    switch (props.codeType) {
      case "CNPJ":
        setIconName("ShopShop");
        break;
      case "CPF":
        setIconName("UserUserUserCloseCircle");
        break;
      case "Celular":
        setIconName("TagsAlertsSlash");
        break;
      case "Chave Aleatória":
        setIconName("ComputersKey");
        break;
      case "Código Pix":
        setIconName("TagsAlertsSlash");
        break;

      default:
        break;
    }
  }, [props.codeType]);

  return (
    <Styles.Container>
      <Styles.Description>
        Existe um {props.codeType} copiado, deseja fazer o pagamento?
      </Styles.Description>
      <Styles.SCard>
        <Avatar icon={iconName} />
        <Styles.TextWrapper>
          <Styles.CardTitle>{props.codeType}</Styles.CardTitle>
          <Styles.CardDescription>{props.code}</Styles.CardDescription>
        </Styles.TextWrapper>
      </Styles.SCard>
      <Button onClick={() => onSubmit(props.code)}>Fazer o pagamento</Button>
    </Styles.Container>
  );
};

export default ClipboardDrawerContent;
