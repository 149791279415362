import { ReactNode } from "react";
import { PaymentContextProvider } from "./payment";
import { RoutesContextProvider } from "./routes";

type AppProviderProps = {
  children: ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
  return (
    <RoutesContextProvider>
      <PaymentContextProvider>{props.children}</PaymentContextProvider>
    </RoutesContextProvider>
  );
};
