import { useCallback, useEffect, useState } from "react";
import {
  getCashOutRoutesData,
  setBoletoData,
  setHeader,
  validateIdentity,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Drawer } from "@hyperlocal/vital";
import { useNavigate } from "react-router-dom";
import { BarcodeReader } from "../../components/barcode-reader";
import { ClipboardDrawerContent } from "../../components/ClipboardDrawerContent";
import { ErrorDrawerContent } from "../../components/ErrorDrawerContent";
import { useRoutes } from "../../context/routes";
import apis from "../../services";
import { getBoletoByCode, IBoleto } from "../../utils/boleto";
import convertBoleto from "../../utils/convertBoleto";
import { sanitizeValidateBarCode } from "../../utils/validate-bar-code/sanitize-validate-bar-code";

export function Boleto() {
  const { routes } = useRoutes();

  const navigate = useNavigate();
  const [reading, setReading] = useState<boolean>(true);
  const [detected, setDetected] = useState<IBoleto>();
  const [drawer, setDrawer] = useState<{
    open: boolean;
    title: string;
    codeType: string;
    description: string;
  }>({ open: false, title: "", codeType: "", description: "" });
  const [barcode, setBarcode] = useState<string>("");
  const [error, setError] = useState<{
    status: boolean;
    message: string;
  }>({ status: false, message: "" });

  const readBoleto = useCallback(
    async (barcode: string) => {
      try {
        const convertido = convertBoleto(barcode);
        const { data } = await apis.validateBarCode(convertido);
        const boletoData = sanitizeValidateBarCode(data);

        setBoletoData({
          amount: boletoData.amount,
          message: boletoData.message,
        });

        setReading(false);
        navigate(getCashOutRoutesData().cashOutBoletoPayment);
      } catch (error) {
        console.log("error boleto", error);
        setDetected(null);
        const data = error?.response?.data;
        setDrawer({
          open: true,
          title: "Erro boleto",
          codeType: "Código de barras",
          description: data?.[0].message
            ? data?.[0].message
            : "Erro leitura boleto",
        });
        setError({
          status: true,
          message: data?.[0].message
            ? data?.[0].message
            : "Erro leitura boleto",
        });
        setBarcode(barcode);
      }
    },
    [navigate],
  );

  // Verifica se o boleto detectado é um boleto válido
  const handleDetected = useCallback(
    (code: string) => {
      try {
        if (!reading) {
          return;
        }

        if (detected) {
          return;
        }

        const boleto = getBoletoByCode(code);
        if (!boleto) {
          throw new Error("Erro boleto");
        }

        setDetected(boleto);
        readBoleto(boleto?.barcode);
      } catch (error) {
        console.log("handleDetected ~ error", error);
      }
    },
    [detected, readBoleto, reading],
  );

  const goToPayment = async (code: string) => {
    try {
      const { data } = await apis.validateBarCode(code);
      const boletoData = sanitizeValidateBarCode(data);
      setBoletoData(boletoData);

      navigate(routes.cashOutBoletoPayment);
      setReading(false);
    } catch (error) {
      const errorMessage = error?.response?.data[0]?.message;
      setDrawer({
        open: true,
        title: "Erro boleto",
        codeType: "Código de barras",
        description: errorMessage || "Erro na leitura do boleto",
      });
      setError({
        status: true,
        message: errorMessage || "Erro na leitura do boleto",
      });
      setBarcode(code);
    }
  };

  useEffect(() => {
    setHeader({
      show: false,
    });
  }, []);

  const handleDrawer = () =>
    setDrawer({
      open: !drawer?.open,
      title: "",
      codeType: "",
      description: "",
    });

  return (
    <>
      {reading && (
        <>
          <BarcodeReader onReadSuccess={handleDetected} onReadError={null} />

          <Drawer
            position="bottom"
            open={drawer?.open}
            title={drawer?.title}
            onDismiss={handleDrawer}
          >
            {!error?.status ? (
              <ClipboardDrawerContent
                code={barcode}
                codeType={drawer.codeType}
                description={drawer.description}
                title={drawer.title}
                onSubmit={(code: string) => goToPayment(code)}
              />
            ) : (
              <ErrorDrawerContent
                type={"boleto"}
                code={barcode}
                description={error?.message}
                onClose={handleDrawer}
                onSubmit={() => navigate(routes.dashboard)}
              />
            )}
          </Drawer>
        </>
      )}
    </>
  );
}
