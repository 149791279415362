import { useCallback, useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getCashOutRoutesData,
  Hooks,
  ProtectedComponent,
  setBoletoData,
  setHeader,
  Types,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Breadcrumb, BreadCrumbItem, Button, Input } from "@hyperlocal/vital";
import { Link, useNavigate } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { ArrowLeft } from "../../../assets/svgs";
import BoletoPaymentInfo from "../../../components/Payment/BoletoPaymentInfo";
import apis from "../../../services";
import { getBoletoByCode } from "../../../utils/boleto";
import { sanitizeValidateBarCode } from "../../../utils/validate-bar-code/sanitize-validate-bar-code";
import BoletoPayment from "../Payment";
import * as Styles from "./styles";

export function BoletoCopyPaste() {
  const routes = getCashOutRoutesData();
  const isMobile = Hooks.useMediaQuery("mobile");

  const navigate = useNavigate();

  const [clipboard] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<{ status: boolean; message: string }>({
    status: false,
    message: "",
  });
  const [boleto, setBoleto] = useState<Types["IBoleto"]>();

  const handleInputChange = (event) => {
    // ? Apenas números
    const result = event.target.value.replace(/\D/g, "");

    setInputValue(result.slice(0, 48));
  };

  const pasteBarCode = () => {
    setInputValue(clipboard);
  };

  const goToPayment = async () => {
    try {
      const boleto = getBoletoByCode(inputValue);

      if (!boleto) {
        setError({ status: true, message: "Código de barras inválido" });
        return;
      }

      const { data } = await apis.validateBarCode(boleto?.number);
      const boletoData = sanitizeValidateBarCode(data);

      setError({ status: false, message: "" });
      setBoletoData(boletoData);

      if (isMobile) {
        navigate(routes.cashOutBoletoPayment);
      } else {
        setBoleto(boletoData);
      }
    } catch {
      setError({
        status: true,
        message: "Erro na leitura do código de barras",
      });
    }
  };

  const handleResetInfo = useCallback(() => {
    setBoleto(null);
    setInputValue("");
  }, []);

  useEffect(() => {
    setHeader({
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigate(routes.dashboard),
      },
      title: "Pagamento",
      show: true,
    });
  }, [navigate, routes.cashOutBoleto]);

  return (
    <Styles.Outer>
      <Styles.Container>
        {!isMobile && (
          <>
            <Breadcrumb onClickIcon={() => navigate(routes.dashboard)}>
              <BreadCrumbItem active>Pagamento de Boleto</BreadCrumbItem>
            </Breadcrumb>

            <Styles.PageTitle>
              <Link to={routes?.dashboard}>
                <ArrowLeft />
              </Link>
              Pagamento de Boleto
            </Styles.PageTitle>
          </>
        )}

        <Styles.PaymentWrapper>
          <Styles.BalanceWrapper>
            {/* @ts-ignore */}
            <Parcel // @ts-ignore
              config={() => System.import("@hyperlocal/banking-balance")}
              mountParcel={mountRootParcel}
            />

            <Styles.CopyPasteWrapper>
              {boleto ? (
                <BoletoPayment resetBoleto={handleResetInfo} />
              ) : (
                <>
                  <Input
                    type="area"
                    label="Código de barras"
                    placeholder="Digite aqui..."
                    value={inputValue}
                    onChange={handleInputChange}
                    helper={error?.status ? "error" : "info"}
                    helperText={error?.status ? error?.message : ""}
                    minLength={44}
                    maxLength={inputValue && 48}
                  />
                  <Styles.ButtonWrapper>
                    {clipboard && isMobile && (
                      <Button variant="secondary" onClick={pasteBarCode}>
                        Colar Código de Barras
                      </Button>
                    )}

                    {!isMobile && (
                      <Button
                        variant="link"
                        onClick={() => navigate(routes.cashOutPixCopiaCola)}
                      >
                        Pix Copia e Cola
                      </Button>
                    )}

                    <ProtectedComponent
                      allowedRoles={[
                        "Proprietário",
                        "Administrador",
                        "Moderador",
                        "Financeiro",
                      ]}
                    >
                      <Button
                        disabled={inputValue.length < 44}
                        onClick={goToPayment}
                      >
                        Continuar
                      </Button>
                    </ProtectedComponent>
                  </Styles.ButtonWrapper>
                </>
              )}
            </Styles.CopyPasteWrapper>
          </Styles.BalanceWrapper>

          {!isMobile && (
            <Styles.ContainerMain>
              {boleto ? (
                <BoletoPaymentInfo payment={boleto} />
              ) : (
                <Parcel
                  config={() => System.import("@hyperlocal/banking-statements")}
                  mountParcel={mountRootParcel}
                  wrapStyle={{
                    height: "100%",
                    overflow: "hidden",
                  }}
                  type={["payment"]}
                />
              )}
            </Styles.ContainerMain>
          )}
        </Styles.PaymentWrapper>
      </Styles.Container>
    </Styles.Outer>
  );
}

export default BoletoCopyPaste;
