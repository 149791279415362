import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from "react";
import { CreatePaymentData } from "../../services/types/create-payment";

type PaymentContextProps = {
  children: ReactNode;
};

type PaymentContextType = {
  createPaymentData: CreatePaymentData;
  setCreatePaymentData: Dispatch<React.SetStateAction<CreatePaymentData>>;
};

export const paymentContext = createContext<PaymentContextType>(
  {} as PaymentContextType,
);

export const PaymentContextProvider = ({ children }: PaymentContextProps) => {
  const [createPaymentData, setCreatePaymentData] =
    useState<CreatePaymentData | null>(null);

  return (
    <paymentContext.Provider
      value={{
        createPaymentData,
        setCreatePaymentData,
      }}
    >
      {children}
    </paymentContext.Provider>
  );
};

export const usePayment = () => {
  const value = useContext(paymentContext);

  return value;
};
