import React, { useEffect, useState } from "react";
import Icon from "@hyperlocal/vital-icons";
import * as Styles from "./styles";
import * as Types from "./types";

export const PaymentValue: React.FC<Types.IPaymentValue> = ({
  emitInputChange,
  ...props
}) => {
  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const [payment, setPayment] = useState<number>(0);

  useEffect(() => {
    setPayment(props.amount);
  }, [props.amount]);

  const clearInput = () => {
    emitInputChange(0);
    setPayment(0);
  };

  const handleInputChange = (event, unmaskedValue) => {
    setPayment(unmaskedValue);
    emitInputChange(unmaskedValue);
  };

  return (
    <Styles.Container>
      <Styles.Label>Valor do Pagamento</Styles.Label>
      <Styles.Value>
        <Styles.Currency>R$</Styles.Currency>
        <Styles.SInput
          onChange={handleInputChange}
          config={currencyConfig}
          value={payment}
          maxLength={12}
          currency="BRL"
          readOnly={props.isFixed}
        />
        {!props.isFixed && (
          <Styles.ClearInput onClick={() => clearInput()}>
            <Icon name="SettingTrashClose" className="closeIcon" />
          </Styles.ClearInput>
        )}
      </Styles.Value>
      <Styles.Caption helper={props.helper}>{props.helperText}</Styles.Caption>
    </Styles.Container>
  );
};

export default PaymentValue;
