import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacings.stack.x2s};

  button {
    justify-content: center;

    & + button {
      margin-top: ${({ theme }) => theme.spacings.stack.x3s};
    }
  }
`;

export const Description = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-size: ${theme.fontSizes.x2s};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.lg};
    margin-bottom: ${theme.spacings.stack.sm};
    color: ${theme.palette.neutral.darker};
    text-align: start;
    overflow-wrap: break-word;
  `}
`;

export const Barcode = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-size: ${theme.fontSizes.x2s};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.lg};
    margin-bottom: ${theme.spacings.stack.sm};
    color: ${theme.palette.neutral.dark};
    text-align: start;
    overflow-wrap: break-word;
  `}
`;

export const DescriptionBoleto = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-size: ${theme.fontSizes.xs};
    font-weight: ${theme.fontWeights.regular};
    line-height: ${theme.lineHeights.lg};
    margin-bottom: ${theme.spacings.stack.x3s};
    color: ${theme.palette.neutral.darker};
    text-align: start;
    overflow-wrap: break-word;
  `}
`;

export const Code = styled.p`
  ${({ theme }) => css`
    font-family: ${theme.fonts.base};
    font-size: ${theme.fontSizes.x3s};
    font-weight: ${theme.fontWeights.medium};
    line-height: ${theme.lineHeights.md};
    color: ${theme.palette.neutral.darkest};
    text-align: start;
    overflow-wrap: break-word;
  `}
`;
